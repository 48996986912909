import aws from "./aws.png";
import angel from "./angel.png";
import angular from "./angular.png";
import canva from "./canva.png";
import CplusPlus from "./CPlusPlus.png";
import CSharp from "./CSharp.png";
import excel from "./excel.png";
import figma from "./figma.png";
import snr from "./snr.png";
import firebase from "./firebase.png";
import git from "./git.png";
import github from "./github.png";
import illustrator from "./illustrator.png";
import Java from "./Java.png";
import linux from "./linux.png";
import mit from "./mit.png";
import mongodb from "./mongodb.png";
import mysql from "./mysql.png";
import nextjs from "./nextjs.png";
import nodejs from "./nodejs.png";
import oracle from "./oracle.png";
import photoshop from "./photoshop.png";
import powerpoint from "./powerpoint.png";
import Python from "./Python.png";
import TypeScript from "./TypeScript.png";
import reactnative from "./reactnative.png";
import windows from "./windows.png";
import wxwidgets from "./wxwidgets.png";
import word from "./word.png";
import vscode from "./vscode.png";
import Flask from "./flask.png";
import foodieHeavenPromo from "./../images/foodieHeavenPromo.png";
import eventsMeetUpPromo from "./../images/eventsMeetUpPromo.jpg";
import soilMindsPromo from "./../images/soilMindsPromo.png";
import retailStoreManageMentSystemPromo from "./../images/retailStoreManageMentSystemPromo.png";
import fileManagerPromo from "./../images/FileManagerPromo.png";
import resumePDF from "./resume.pdf";

export const Bio = {
  name: "John Prabu A",
  roles: [
    "an Aspiring Full Stack Dev",
    "a Cloud Enthusiast",
    "a Tech Innovator",
    "a Freelancer"
  ],
  description:
    "I am a motivated and adaptable individual with a strong enthusiasm for new challenges. Committed to delivering high-quality results, I approach every task with a positive attitude and a growth mindset. I am eager to contribute meaningfully and achieve exceptional outcomes.",
  github: "https://github.com/John-Prabu-A",
  resume: resumePDF,
  linkedin: "https://www.linkedin.com/in/johnprabu",
  twitter: "https://x.com/JohnPrabuA",
  insta: "https://www.instagram.com/john_prabu_mit",
};

export const skills = [
  {
    title: "Programming languages",
    skills: [
      {
        name: "C++",
        image: CplusPlus,
      },
      {
        name: "Python (PY)",
        image: Python,
      },
      {
        name: "TypeScript (TS)",
        image: TypeScript,
      },
      {
        name: "JAVA",
        image: Java,
      },
      {
        name: "C#",
        image: CSharp,
      },
    ],
  },
  {
    title: "Database technologies",
    skills: [
      {
        name: "MySQL",
        image: mysql,
      },
      {
        name: "Oracle",
        image: oracle,
      },
      {
        name: "MongoDB",
        image: mongodb,
      },
      {
        name: "Firebase",
        image: firebase,
      },
      {
        name: "Postgresql",
        image: "https://www.postgresql.org/media/img/about/press/elephant.png",
      },
    ],
  },
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "React Native",
        image: reactnative,
      },
      {
        name: "Redux",
        image:
          "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      },
      {
        name: "Next Js",
        image: nextjs,
      },
      {
        name: "Angular Js",
        image: angular,
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
      {
        name: "wxWidgets",
        image: wxwidgets,
      },
    ],
  },
  {
    title: "Other Tools & Technologies",
    skills: [
      
      {
        name: "Git",
        image: git,
      },
      {
        name: "GitHub",
        image: github,
      },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "Postman",
        image:
          "https://static-00.iconduck.com/assets.00/postman-icon-497x512-beb7sy75.png",
      },
      {
        name: "Adobe Photoshop",
        image: photoshop,
      },
      {
        name: "Canva",
        image: canva,
      },
      {
        name: "Excel",
        image: excel,
      },
      {
        name: "Word",
        image: word,
      },
      {
        name: "Microsoft PowerPoint",
        image: powerpoint,
      },
      {
        name: "Figma",
        image: figma,
      },
      {
        name: "Adobe Illustrator",
        image: illustrator,
      },
      {
        name: "VS Code",
        image: vscode,
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: nodejs,
      },
      {
        name: "Flask",
        image: Flask,
      },
      {
        name: "Django",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlFFyY16N5NRhzoG24RqB7x8Ok2t8Bdgs-tuMsOBXS2Q&s",
      },
    ],
  },
  {
    title: "DevOps",
    skills: [
      {
        name: "AWS",
        image: aws,
      },
      {
        name: "Docker",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      },
      {
        name: "Nginx",
        image: "https://download.logo.wine/logo/Nginx/Nginx-Logo.wine.png",
      },
    ],
  },
  {
    title: "Operating Systems",
    skills: [
      {
        name: "Linux",
        image: linux,
      },
      {
        name: "Windows",
        image: windows,
      },
    ],
  },
];

export const education = [
  {
    id: 0,
    img: mit,
    school: "Madras Institute of Technology, Anna University",
    location: "Chennai, India",
    date: "Jul 2022 - Apr 2026",
    grade: "8.26 CGPA",
    desc: "I am currently pursuing a Bachelor's degree in Information Technology at Madras Institute of Technology, Anna University. I have completed 4 semesters and maintained a CGPA of 8.26. I have gained solid knowledge in software development, cloud computing, and database management, as well as hands-on experience with web technologies. My goal is to apply these skills in real-world projects and further enhance my technical expertise.",
    degree: "Bachelor of Technology - BTech, Information Technology",
  },
  {
    id: 1,
    img: snr,
    school: "S.N.R Higher Secondary School, Sankarankovil",
    location: "Tenkasi, Tamil Nadu",
    date: "Jun 2020 - May 2022",
    grade: "95% (570/600)",
    desc: "Completed my Higher Secondary education with a specialization in Science. Studied core subjects including Physics, Chemistry, Mathematics, and Computer Science. Developed a strong foundational understanding of programming and problem-solving.",
    degree: "Higher Secondary Education (12th Grade), Science Stream",
  },
  {
    id: 2,
    img: angel,
    school: "Angel High School, Sankarankovil",
    location: "Tenkasi, Tamil Nadu",
    date: "Jun 2018 - Mar 2020",
    grade: "97.8% (489/500)",
    desc: "Completed my High School education, securing excellent grades. This period helped me solidify my interest in technology and science, which paved the way for my journey into Information Technology.",
    degree: "High School Education (10th Grade)",
  },
];

export const projects = [
  {
    id: 0,
    title: "Events MeetUp Application with AI-Powered Recommendations",
    date: "Nov 2024",
    description:
      "Developed a personalized meetup platform that uses vector embeddings and a recommendation engine to suggest events based on user interests and interactions. Leveraging a embeddings model, the application identifies user preferences from their engagement patterns, enabling tailored event recommendations. Key features include geolocation-based event discovery, real-time chat for attendees, and organizer tools for managing events and notifications.",
    image: eventsMeetUpPromo,
    tags: ["React Native", "NodeJS", "REST API", "Supabase", "Vector Embeddings", "Recommendation Engine", "AI"],
    category: "mobile app",
    github: "https://github.com/John-Prabu-A/EventMeetUp",
  },
  
  {
    id: 1,
    title: "Foodie Heaven (A Food-Ordering App – Cross-Platform)",
    date: "Jul 2024",
    description:
      "Developed a cross-platform food ordering app for iOS and Android using React-native and Supabase, an open-source alternative to Firebase. The app allows users to browse restaurants, place orders, and receive real-time updates on their food delivery status.",
    image: foodieHeavenPromo,
    tags: ["React Native", "TypeScript", "Expo Router", "Supabase"],
    category: "mobile app",
    github: "https://github.com/John-Prabu-A/Food-Ordering-App-React-Native",
  },
  {
    id: 2,
    title: "Soil Minds (Crop Recommendation System)",
    date: "Jan 2024",
    description:
      "Developed a system that helps farmers select the right crops and pesticides based on soil and environmental data. The application uses a decision tree algorithm to provide recommendations and features an interactive user interface with real-time data processing.",
    image: soilMindsPromo,
    tags: ["ReactJS", "NodeJS", "SQL"],
    category: "web app",
    github: "https://github.com/John-Prabu-A/Soil-Minds",
  },
  {
    id: 3,
    title: "Retail Shop Management System",
    date: "Nov 2023",
    description:
      "Built a comprehensive shop management system for retail businesses. The system includes inventory management, sales tracking, and customer management functionalities, with a robust backend to ensure seamless data handling.",
    image: retailStoreManageMentSystemPromo,
    tags: ["HTML", "CSS", "JavaScript", "PHP", "MySQL"],
    category: "web app",
    github: "https://github.com/John-Prabu-A/Retail-Store-Management-System",
  },
  {
    id: 4,
    title: "File Manager Software (Cross-Platform GUI App)",
    date: "May 2024",
    description:
      "Designed a file manager for Linux, Windows, and macOS using C++ and wxWidgets. This software provides an intuitive graphical user interface (GUI) for users to manage files efficiently across different platforms.",
    image: fileManagerPromo,
    tags: ["C++", "wxWidgets"],
    category: "desktop app",
    github: "https://github.com/John-Prabu-A/wx_file_manager_project",
  },
];
